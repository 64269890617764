import CryptoJS from "crypto-js";
import { setCookie } from "../utils/common";
import { postJson, putJson } from "./fetch";

export async function sendLoginRequest(username, password) {
  const hashedPassword = CryptoJS.MD5(password).toString();
  const userData = {
    name: username,
    phone: username,
    email: username,
    password: hashedPassword,
  };

  const result = await postJson("/api/user/login", userData);
  console.log("cookie", result.data);
  setCookie("stockservant_token", result.data, 7);
  return result.isSuccess;
}

export async function sendRegisterRequest(username, email, phone, password, inviteCode) {
  const hashedPassword = CryptoJS.MD5(password).toString();
  const userData = {
    name: username,
    phone: phone,
    email: email,
    password: hashedPassword,
  };

  const result = await putJson(`/api/user?inviteCode=${inviteCode}`, userData);

  return result.isSuccess;
}
